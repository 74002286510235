import moment from "moment";
export const SolicitudForm = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  NecesidadSolicitante: "VALES GRANDEZA",
  CostoNecesidad: 500,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  TipoAsentamiento: "",
  FechaINE: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  Files: [],
  ArchivosClasificacion: [],
  ListaParaEnviar: 0,
  idEstatus: 1,
  Region: "",
  idEnlace: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
  IngresoPercapita: "",
  PersonasDependientes: "",
  TotalIngreso: "",
  OtrosIngresos: "",
  IngresoMensual: "",
  OcupacionJefeHogar: "",
  FolioSolicitud: "",
  ValidadoTarjetaContigoSi: "",
  Formato: "",
};

export const SolicitudFormC = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  NecesidadSolicitante: "CALENTADOR SOLAR",
  CostoNecesidad: 8145,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  Files: [],
  ArchivosClasificacion: [],
  ListaParaEnviar: 0,
  Region: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
  Formato: "",
  FechaINE: "",
};

export const FormatoSolicitudCalentador = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  CURPTutor: "",
  idMunicipio: "",
  idLocalidad: "",
  CP: "",
  Colonia: "",
  Calle: "",
  NumExt: "",
  NumInt: "",
  Referencias: "",
  Formato: 1,
  Files: [],
  ArchivosClasificacion: [],
  idEstatusSolicitud: 1,
  Region: "",
  Enlace: "",
  FechaINE: "",
  Ejercicio: 2024,
};

export const Solicitud = {
  FechaSolicitud: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  Municipio: "",
  Localidad: "",
  CP: "",
  Colonia: "",
  Calle: "",
  NumExt: "",
  NumInt: "",
  Referencias: "",
  Files: [],
  ArchivosClasificacion: [],
  ArchivosEstatus: [],
  Region: "",
  Enlace: "",
  Estatus: "",
  FechaINE: "",
};

export const SolicitudFormYP = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  NecesidadSolicitante: "PROYECTO PRODUCTIVO",
  CostoNecesidad: 45000,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  Files: [],
  ArchivosClasificacion: [],
  ListaParaEnviar: 0,
  Region: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
  Formato: "",
  FechaINE: "",
};

export const SolicitudFormYoPuedo = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  NecesidadSolicitante: "CAPACITACIÓN DEL PROGRAMA YO PUEDO, GTO PUEDE",
  CostoNecesidad: "NO APLICA",
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  ListaParaEnviar: 0,
  Region: "",
  idEnlace: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
  idGrupo: "",
  idMunicipioGrupo: "",
  idEstatusGrupo: 1,
  FolioYoPuedo: "",
};

export const SolicitudFormDiagnosticos = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  NecesidadSolicitante: "DIAGNOSTICO",
  CostoNecesidad: "NO APLICA",
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  ListaParaEnviar: 0,
  Region: "",
  idEnlace: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
};

export const SolicitudFormProyectos = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "H",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: 0,
  NumHijas: 0,
  ComunidadIndigena: "",
  Dialecto: "",
  Afromexicano: 0,
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  Celular: "",
  Telefono: "",
  TelRecados: "",
  Correo: "",
  NecesidadSolicitante: "",
  CostoNecesidad: 0,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  ListaParaEnviar: 0,
  Region: "",
  Enlace: "",
  Files: [],
  ArchivosClasificacion: [],
  Formato: "",
  FechaINE: "",
};

export const FormatedSolicitudForm = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento,
    CURP: form.CURP,
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil,
    idParentescoJefeHogar: form.idParentescoJefeHogar,
    NumHijos: form.NumHijos,
    NumHijas: form.NumHijas,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano,
    idSituacionActual: form.idSituacionActual,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    NecesidadSolicitante: form.NecesidadSolicitante,
    CostoNecesidad: form.CostoNecesidad,
    idEntidadVive: form.idEntidadVive,
    MunicipioVive: form.MunicipioVive,
    LocalidadVive: form.LocalidadVive,
    TipoAsentamiento: form.TipoAsentamiento,
    CPVive: form.CPVive,
    ColoniaVive: form.ColoniaVive,
    CalleVive: form.CalleVive,
    NoExtVive: form.NoExtVive,
    NoIntVive: form.NoIntVive,
    Referencias: form.Referencias,
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    idEstatus: form.idEstatus ? form.idEstatus : 1,
    Region: form.Region ? form.Region : "",
    idEnlace: form.idEnlace ? form.idEnlace : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
    IngresoPercapita: form.IngresoPercapita ? form.IngresoPercapita : "",
    PersonasDependientes: form.PersonasDependientes
      ? form.PersonasDependientes
      : "",
    TotalIngreso: form.TotalIngreso ? form.TotalIngreso : "",
    OtrosIngresos: form.OtrosIngresos ? form.OtrosIngresos : "",
    IngresoMensual: form.IngresoMensual !== null ? form.IngresoMensual : "",
    OcupacionJefeHogar: form.OcupacionJefeHogar ? form.OcupacionJefeHogar : "",
    idGrupo: form.idGrupo ? form.idGrupo : "",
    idMunicipioGrupo: form.idMunicipioGrupo ? form.idMunicipioGrupo : "",
    idEstatusGrupo: form.idEstatusGrupo ? form.idEstatusGrupo : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
    FolioSolicitud: form.FolioSolicitud ? form.FolioSolicitud : "",
    ValidadoTarjetaContigoSi: form.ValidadoTarjetaContigoSi
      ? form.ValidadoTarjetaContigoSi
      : "",
    Formato: form.Formato ? form.Formato : "",
  };
};
export const FormatedSolicitudVale = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Sexo: form.Sexo,
    CURP: form.CURP,
    Celular: form.TelCelular,
    Telefono: form.TelFijo ? form.TelFijo : "",
    Correo: form.Correo ? form.Correo : "",
    Municipio: form.Municipio,
    Localidad: form.Localidad,
    CP: form.CP,
    Colonia: form.Colonia,
    Calle: form.Calle,
    NumExt: form.NumExt,
    NumInt: form.NumInt,
    ResponsableEntrega: form.Responsable ? form.Responsable : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
    FolioSolicitud: form.FolioSolicitud ? form.FolioSolicitud : "",
  };
};

export const FormatedSolicitudFormC = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : null,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento,
    CURP: form.CURP,
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil,
    idParentescoJefeHogar: form.idParentescoJefeHogar,
    NumHijos: form.NumHijos,
    NumHijas: form.NumHijas,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano,
    idSituacionActual: form.idSituacionActual,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    NecesidadSolicitante: form.NecesidadSolicitante,
    CostoNecesidad: form.CostoNecesidad,
    idEntidadVive: form.idEntidadVive,
    MunicipioVive: form.MunicipioVive,
    LocalidadVive: form.LocalidadVive,
    CPVive: form.CPVive,
    ColoniaVive: form.ColoniaVive,
    CalleVive: form.CalleVive,
    NoExtVive: form.NoExtVive,
    NoIntVive: form.NoIntVive,
    Referencias: form.Referencias,
    Celular: form.Celular,
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Region: form.Region ? form.Region : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
    Formato: form.Formato ? form.Formato : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
  };
};

export const FormatoCalentador = (form) => {
  console.log(form);
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : null,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    idMunicipio: form.idMunicipio,
    idLocalidad: form.idLocalidad,
    CP: form.CP,
    Colonia: form.Colonia,
    Calle: form.Calle,
    NumExt: form.NumExt ? form.NumExt : "",
    NumInt: form.NumInt ? form.NumInt : "",
    Formato: form.Formato ? form.Formato : 1,
    Referencias: form.Referencias,
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    idEstatusSolicitud: form.idEstatusSolicitud ? form.idEstatusSolicitud : 1,
    Region: form.Region ? form.Region : "",
    Enlace: form.Enlace ? form.Enlace : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
    Ejercicio: form.Ejercicio ? form.Ejercicio : 2024,
  };
};

export const FormatedSolicitud = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : null,
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Sexo: form.Sexo,
    CURP: form.CURP,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    Correo: form.Correo ? form.Correo : "",
    idEntidadVive: form.idEntidadVive,
    Municipio: form.MunicipioVive,
    Localidad: form.LocalidadVive,
    CP: form.CPVive,
    Colonia: form.ColoniaVive,
    Calle: form.CalleVive,
    NumExt: form.NumExtVive,
    NumInt: form.NumIntVive,
    Referencias: form.Referencias,
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    ArchivosEstatus: form.ArchivosEstatus ? form.ArchivosEstatus : [],
    Region: form.Region ? form.RegionM : "",
    Enlace: form.Enlace ? form.Enlace : "",
    //Estatus: form.Estatus ? form.Estatus : "",
  };
};

export const FormatedSolicitudFormYoPuedo = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento,
    CURP: form.CURP,
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil,
    idParentescoJefeHogar: form.idParentescoJefeHogar,
    NumHijos: form.NumHijos,
    NumHijas: form.NumHijas,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano,
    idSituacionActual: form.idSituacionActual,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    NecesidadSolicitante: "CAPACITACIÓN DEL PROGRAMA YO PUEDO, GTO PUEDE",
    CostoNecesidad: "NO APLICA",
    idEntidadVive: form.idEntidadVive,
    MunicipioVive: form.MunicipioVive,
    LocalidadVive: form.LocalidadVive,
    CPVive: form.CPVive,
    ColoniaVive: form.ColoniaVive,
    CalleVive: form.CalleVive,
    NoExtVive: form.NoExtVive,
    NoIntVive: form.NoIntVive,
    Referencias: form.Referencias,
    Celular: form.Celular,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Region: form.Region ? form.Region : "",
    idEnlace: form.idEnlace ? form.idEnlace : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
    idGrupo: form.idGrupo ? form.idGrupo : "",
    idMunicipioGrupo: form.idMunicipioGrupo ? form.idMunicipioGrupo : "",
    idEstatusGrupo: form.idEstatusGrupo ? form.idEstatusGrupo : 1,
    FolioYoPuedo: form.FolioYoPuedo ? form.FolioYoPuedo : "",
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Formato: form.Formato ? form.Formato : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
  };
};

export const FormatedSolicitudFormDiagnosticos = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento,
    CURP: form.CURP,
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil,
    idParentescoJefeHogar: form.idParentescoJefeHogar,
    NumHijos: form.NumHijos,
    NumHijas: form.NumHijas,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano,
    idSituacionActual: form.idSituacionActual,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular,
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    NecesidadSolicitante: "DIAGNOSTICO",
    CostoNecesidad: "NO APLICA",
    idEntidadVive: form.idEntidadVive,
    MunicipioVive: form.MunicipioVive,
    LocalidadVive: form.LocalidadVive,
    CPVive: form.CPVive,
    ColoniaVive: form.ColoniaVive,
    CalleVive: form.CalleVive,
    NoExtVive: form.NoExtVive,
    NoIntVive: form.NoIntVive,
    Referencias: form.Referencias,
    Celular: form.Celular,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Region: form.Region ? form.Region : "",
    idEnlace: form.idEnlace ? form.idEnlace : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
  };
};

export const FormatedSolicitudFormProyectos = (form) => {
  return {
    id: form.id,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : null,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno,
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad,
    Sexo: form.Sexo,
    idEntidadNacimiento: form.idEntidadNacimiento,
    CURP: form.CURP,
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil,
    idParentescoJefeHogar: form.idParentescoJefeHogar,
    NumHijos: form.NumHijos,
    NumHijas: form.NumHijas,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    idSituacionActual: form.idSituacionActual,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular ? form.Celular : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    Correo: form.Correo ? form.Correo : "",
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : "",
    idEntidadVive: form.idEntidadVive,
    MunicipioVive: form.MunicipioVive,
    LocalidadVive: form.LocalidadVive,
    CPVive: form.CPVive,
    ColoniaVive: form.ColoniaVive,
    CalleVive: form.CalleVive,
    NoExtVive: form.NoExtVive,
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Region: form.Region ? form.Region : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Formato: form.Formato ? form.Formato : "",
    FechaINE: form.FechaINE ? form.FechaINE : "",
  };
};

export const FormatedCedulaFormDiagnostico = (form) => {
  return {
    id: form.id,
    Folio: form.Folio ? form.Folio : "",
    FechaSolicitud: form.FechaSolicitud,
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento,
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "H",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    idSituacionActual: form.idSituacionActual ? form.idSituacionActual : 0,
    TarjetaImpulso: form.TarjetaImpulso,
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor,
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : 0,
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 0,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : "",
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : "",
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer,
    DificultadHablar: form.DificultadHablar,
    DificultadOir: form.DificultadOir,
    DificultadVestirse: form.DificultadVestirse,
    DificultadRecordar: form.DificultadRecordar,
    DificultadBrazos: form.DificultadBrazos,
    DificultadMental: form.DificultadMental,
    AsisteEscuela: form.AsisteEscuela ? form.AsisteEscuela : 0,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 0,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    Refrigerador: form.Refrigerador ? form.Refrigerador : "",
    Lavadora: form.Lavadora ? form.Lavadora : "",
    Computadora: form.Computadora ? form.Computadora : "",
    Estufa: form.Estufa ? form.Estufa : "",
    Calentador: form.Calentador ? form.Calentador : "",
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : "",
    Television: form.Television ? form.Television : "",
    Internet: form.Internet ? form.Internet : "",
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : "",
    Tinaco: form.Tinaco ? form.Tinaco : "",
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : "",
    idEstatus: form.idEstatus ? form.idEstatus : "",
    UsuarioAplicativo: form.UsuarioAplicativo ? form.UsuarioAplicativo : "",
    Enlace: form.Enlace ? form.Enlace : "",
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    idUsuarioCreo: form.idUsuarioCreo ? form.idUsuarioCreo : "",
    FechaCreo: form.FechaCreo ? form.FechaCreo : "",
    idUsuarioActualizo: form.idUsuarioActualizo ? form.idUsuarioActualizo : "",
    FechaActualizo: form.FechaActualizo ? form.FechaActualizo : "",
    idUsuarioElimino: form.idUsuarioElimino ? form.idUsuarioElimino : "",
    FechaElimino: form.FechaElimino ? form.FechaElimino : "",
    idSolicitudAplicativo: form.idSolicitudAplicativo
      ? form.idSolicitudAplicativo
      : "",
  };
};

export const CedulaFormInterface = {
  idSolicitud: "",
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  Celular: "",
  Correo: "",
  Telefono: "",
  TelRecados: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: "",
  NumHijas: "",
  Afromexicano: "",
  ComunidadIndigena: "",
  Dialecto: "",
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  NecesidadSolicitante: "",
  CostoNecesidad: 500,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  AGEBVive: "",
  ManzanaVive: "",
  TipoAsentamientoVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  TotalHogares: 1,
  NumeroMujeresHogar: 0,
  NumeroHombresHogar: 0,
  PersonasMayoresEdad: 0,
  PersonasTerceraEdad: 0,
  PersonaJefaFamilia: "H",
  DificultadMovilidad: "",
  DificultadVer: "",
  DificultadHablar: "",
  DificultadOir: "",
  DificultadVestirse: "",
  DificultadRecordar: "",
  DificultadBrazos: "",
  DificultadMental: "",
  AsisteEscuela: "",
  idNivelEscuela: "",
  idGradoEscuela: "",
  idActividades: "",
  IngresoTotalMesPasado: 0,
  PensionMensual: 0,
  IngresoOtrosPaises: 0,
  GastoAlimentos: 0,
  PeriodicidadAlimentos: "",
  GastoVestido: 0,
  PeriodicidadVestido: "",
  GastoEducacion: 0,
  PeriodicidadEducacion: "",
  GastoMedicinas: 0,
  PeriodicidadMedicinas: "",
  GastosConsultas: 0,
  PeriodicidadConsultas: "",
  GastosCombustibles: 0,
  PeriodicidadCombustibles: "",
  GastosServiciosBasicos: 0,
  PeriodicidadServiciosBasicos: "",
  GastosServiciosRecreacion: 0,
  PeriodicidadServiciosRecreacion: "",
  AlimentacionPocoVariada: 0,
  ComioMenos: 0,
  DisminucionComida: 0,
  NoComio: 0,
  DurmioHambre: 0,
  DejoComer: 0,
  PersonasHogar: 1,
  CuartosHogar: 0,
  idTipoVivienda: "",
  idTipoPiso: "",
  idTipoParedes: "",
  idTipoTecho: "",
  idTipoAgua: "",
  idTipoDrenaje: "",
  idTipoLuz: "",
  idTipoCombustible: "",
  idTipoCombustibleAgua: "",
  idTipoCombustibleBoiler: [],
  Refrigerador: 0,
  Lavadora: 0,
  Computadora: 0,
  Estufa: 0,
  Calentador: 0,
  CalentadorSolar: 0,
  Television: 0,
  Internet: 0,
  TieneTelefono: 0,
  Tinaco: 0,
  Boiler: 0,
  CantidadCombustibleBoiler: "",
  GastoCombustibleBoiler: "",
  ColoniaSegura: 0,
  ListaParaEnviar: 0,
  Prestaciones: [],
  Enfermedades: [],
  AtencionesMedicas: [],
  Files: [],
  ArchivosClasificacion: [],
  Region: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
};

export const CedulaFormInterfaceYoPuedo = {
  idSolicitud: "",
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  Celular: "",
  Correo: "",
  Telefono: "",
  TelRecados: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: "",
  NumHijas: "",
  Afromexicano: "",
  ComunidadIndigena: "",
  Dialecto: "",
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  NecesidadSolicitante: "CAPACITACIÓN DEL PROGRAMA YO PUEDO, GTO PUEDE",
  CostoNecesidad: "NO APLICA",
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  AGEBVive: "",
  ManzanaVive: "",
  TipoAsentamientoVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  TotalHogares: 1,
  NumeroMujeresHogar: 0,
  NumeroHombresHogar: 0,
  PersonasMayoresEdad: 0,
  PersonasTerceraEdad: 0,
  PersonaJefaFamilia: "H",
  DificultadMovilidad: "",
  DificultadVer: "",
  DificultadHablar: "",
  DificultadOir: "",
  DificultadVestirse: "",
  DificultadRecordar: "",
  DificultadBrazos: "",
  DificultadMental: "",
  AsisteEscuela: 0,
  idNivelEscuela: "",
  idGradoEscuela: "",
  idActividades: "",
  IngresoTotalMesPasado: 0,
  PensionMensual: 0,
  IngresoOtrosPaises: 0,
  GastoAlimentos: 0,
  PeriodicidadAlimentos: "",
  GastoVestido: 0,
  PeriodicidadVestido: "",
  GastoEducacion: 0,
  PeriodicidadEducacion: "",
  GastoMedicinas: 0,
  PeriodicidadMedicinas: "",
  GastosConsultas: 0,
  PeriodicidadConsultas: "",
  GastosCombustibles: 0,
  PeriodicidadCombustibles: "",
  GastosServiciosBasicos: 0,
  PeriodicidadServiciosBasicos: "",
  GastosServiciosRecreacion: 0,
  PeriodicidadServiciosRecreacion: "",
  AlimentacionPocoVariada: 0,
  ComioMenos: 0,
  DisminucionComida: 0,
  NoComio: 0,
  DurmioHambre: 0,
  DejoComer: 0,
  PersonasHogar: 1,
  CuartosHogar: 0,
  idTipoVivienda: "",
  idTipoPiso: "",
  idTipoParedes: "",
  idTipoTecho: "",
  idTipoAgua: "",
  idTipoDrenaje: "",
  idTipoLuz: "",
  idTipoCombustible: "",
  idTipoCombustibleAgua: "",
  idTipoCombustibleBoiler: [],
  Refrigerador: 0,
  Lavadora: 0,
  Computadora: 0,
  Estufa: 0,
  Calentador: 0,
  CalentadorSolar: 0,
  Television: 0,
  Internet: 0,
  TieneTelefono: 0,
  Tinaco: 0,
  Boiler: 0,
  CantidadCombustibleBoiler: "",
  GastoCombustibleBoiler: "",
  ColoniaSegura: 0,
  ListaParaEnviar: 0,
  Prestaciones: [],
  Enfermedades: [],
  AtencionesMedicas: [],
  Files: [],
  ArchivosClasificacion: [],
  Region: "",
  idEnlace: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
  idGrupo: "",
  idMunicipioGrupo: "",
  idEstatusGrupo: "",
};

export const CedulaFormInterfaceDiagnosticos = {
  idSolicitud: "",
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  Celular: "",
  Correo: "",
  Telefono: "",
  TelRecados: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: "",
  NumHijas: "",
  Afromexicano: "",
  ComunidadIndigena: "",
  Dialecto: "",
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  NecesidadSolicitante: "DIAGNOSTICO",
  CostoNecesidad: "NO APLICA",
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  AGEBVive: "",
  ManzanaVive: "",
  TipoAsentamientoVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  idParentescoTutor: "",
  NombreTutor: "",
  PaternoTutor: "",
  MaternoTutor: "",
  FechaNacimientoTutor: "",
  EdadTutor: "",
  SexoTutor: "",
  idEntidadNacimientoTutor: "",
  CURPTutor: "",
  TelefonoTutor: "",
  CorreoTutor: "",
  TotalHogares: 1,
  NumeroMujeresHogar: 0,
  NumeroHombresHogar: 0,
  PersonasMayoresEdad: 0,
  PersonasTerceraEdad: 0,
  PersonaJefaFamilia: "H",
  DificultadMovilidad: "",
  DificultadVer: "",
  DificultadHablar: "",
  DificultadOir: "",
  DificultadVestirse: "",
  DificultadRecordar: "",
  DificultadBrazos: "",
  DificultadMental: "",
  AsisteEscuela: 0,
  idNivelEscuela: "",
  idGradoEscuela: "",
  idActividades: "",
  IngresoTotalMesPasado: 0,
  PensionMensual: 0,
  IngresoOtrosPaises: 0,
  GastoAlimentos: 0,
  PeriodicidadAlimentos: "",
  GastoVestido: 0,
  PeriodicidadVestido: "",
  GastoEducacion: 0,
  PeriodicidadEducacion: "",
  GastoMedicinas: 0,
  PeriodicidadMedicinas: "",
  GastosConsultas: 0,
  PeriodicidadConsultas: "",
  GastosCombustibles: 0,
  PeriodicidadCombustibles: "",
  GastosServiciosBasicos: 0,
  PeriodicidadServiciosBasicos: "",
  GastosServiciosRecreacion: 0,
  PeriodicidadServiciosRecreacion: "",
  AlimentacionPocoVariada: 0,
  ComioMenos: 0,
  DisminucionComida: 0,
  NoComio: 0,
  DurmioHambre: 0,
  DejoComer: 0,
  PersonasHogar: 1,
  CuartosHogar: 0,
  idTipoVivienda: "",
  idTipoPiso: "",
  idTipoParedes: "",
  idTipoTecho: "",
  idTipoAgua: "",
  idTipoDrenaje: "",
  idTipoLuz: "",
  idTipoCombustible: "",
  idTipoCombustibleAgua: "",
  idTipoCombustibleBoiler: [],
  Refrigerador: 0,
  Lavadora: 0,
  Computadora: 0,
  Estufa: 0,
  Calentador: 0,
  CalentadorSolar: 0,
  Television: 0,
  Internet: 0,
  TieneTelefono: 0,
  Tinaco: 0,
  Boiler: 0,
  CantidadCombustibleBoiler: "",
  GastoCombustibleBoiler: "",
  ColoniaSegura: 0,
  ListaParaEnviar: 0,
  Prestaciones: [],
  Enfermedades: [],
  AtencionesMedicas: [],
  Files: [],
  ArchivosClasificacion: [],
  Region: "",
  idEnlace: "",
  Enlace: "",
  Latitud: "",
  Longitud: "",
};

export const FormatNullCedulaFields = (form) => {
  return {
    id: form.id,
    idSolicitud: form.idSolicitud,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : "",
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento ? form.FechaNacimiento : "",
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    idSituacionActual: form.idSituacionActual ? form.idSituacionActual : "",
    TarjetaImpulso: form.TarjetaImpulso ? form.TarjetaImpulso : "",
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "Apoyo para adquirir artículos de primera necesidad y/o farmacéuticos",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : 500,
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 1,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : 0,
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : 0,
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer ? form.DificultadVer : "",
    DificultadHablar: form.DificultadHablar ? form.DificultadHablar : "",
    DificultadOir: form.DificultadOir ? form.DificultadOir : "",
    DificultadVestirse: form.DificultadVestirse ? form.DificultadVestirse : "",
    DificultadRecordar: form.DificultadRecordar ? form.DificultadRecordar : "",
    DificultadBrazos: form.DificultadBrazos ? form.DificultadBrazos : "",
    DificultadMental: form.DificultadMental ? form.DificultadMental : "",
    AsisteEscuela: form.AsisteEscuela < 0 ? "" : form.AsisteEscuela,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 1,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    idTipoCombustibleAgua: form.idTipoCombustibleAgua
      ? form.idTipoCombustibleAgua
      : "",
    idTipoCombustibleBoiler: form.idTipoCombustibleBoiler
      ? form.idTipoCombustibleBoiler
      : [],
    Refrigerador: form.Refrigerador ? form.Refrigerador : 0,
    Lavadora: form.Lavadora ? form.Lavadora : 0,
    Computadora: form.Computadora ? form.Computadora : 0,
    Estufa: form.Estufa ? form.Estufa : 0,
    Calentador: form.Calentador ? form.Calentador : 0,
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : 0,
    Television: form.Television ? form.Television : 0,
    Internet: form.Internet ? form.Internet : 0,
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : 0,
    Tinaco: form.Tinaco ? form.Tinaco : 0,
    Boiler: form.Boiler ? form.Boiler : 0,
    CantidadCombustibleBoiler: form.CantidadCombustibleBoiler
      ? form.CantidadCombustibleBoiler
      : "",
    GastoCombustibleBoiler: form.GastoCombustibleBoiler
      ? form.GastoCombustibleBoiler
      : "",
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : 0,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Prestaciones: form.Prestaciones ? form.Prestaciones : [],
    Enfermedades: form.Enfermedades ? form.Enfermedades : [],
    AtencionesMedicas:
      form.AtencionesMedicas && form.AtencionesMedicas != ""
        ? form.AtencionesMedicas
        : [],
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Region: form.Region ? form.Region : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
  };
};

export const FormatNullCedulaFieldsYoPuedo = (form) => {
  return {
    id: form.id,
    idSolicitud: form.idSolicitud,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : "",
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento ? form.FechaNacimiento : "",
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    idSituacionActual:
      form.idSituacionActual >= 0 ? form.idSituacionActual : "",
    TarjetaImpulso: form.TarjetaImpulso ? form.TarjetaImpulso : "",
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "CAPACITACIÓN DEL PROGRAMA YO PUEDO, GTO PUEDE",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : "NO APLICA",
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 1,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : 0,
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : 0,
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer ? form.DificultadVer : "",
    DificultadHablar: form.DificultadHablar ? form.DificultadHablar : "",
    DificultadOir: form.DificultadOir ? form.DificultadOir : "",
    DificultadVestirse: form.DificultadVestirse ? form.DificultadVestirse : "",
    DificultadRecordar: form.DificultadRecordar ? form.DificultadRecordar : "",
    DificultadBrazos: form.DificultadBrazos ? form.DificultadBrazos : "",
    DificultadMental: form.DificultadMental < 0 ? "" : form.DificultadMental,
    AsisteEscuela: form.AsisteEscuela < 0 ? 0 : form.AsisteEscuela,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 1,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    idTipoCombustibleAgua: form.idTipoCombustibleAgua
      ? form.idTipoCombustibleAgua
      : "",
    idTipoCombustibleBoiler: form.idTipoCombustibleBoiler
      ? form.idTipoCombustibleBoiler
      : [],
    Refrigerador: form.Refrigerador ? form.Refrigerador : 0,
    Lavadora: form.Lavadora ? form.Lavadora : 0,
    Computadora: form.Computadora ? form.Computadora : 0,
    Estufa: form.Estufa ? form.Estufa : 0,
    Calentador: form.Calentador ? form.Calentador : 0,
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : 0,
    Television: form.Television ? form.Television : 0,
    Internet: form.Internet ? form.Internet : 0,
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : 0,
    Tinaco: form.Tinaco ? form.Tinaco : 0,
    Boiler: form.Boiler ? form.Boiler : 0,
    CantidadCombustibleBoiler: form.CantidadCombustibleBoiler
      ? form.CantidadCombustibleBoiler
      : "",
    GastoCombustibleBoiler: form.GastoCombustibleBoiler
      ? form.GastoCombustibleBoiler
      : "",
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : 0,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Prestaciones: form.Prestaciones ? form.Prestaciones : [],
    Enfermedades: form.Enfermedades ? form.Enfermedades : [],
    AtencionesMedicas:
      form.AtencionesMedicas && form.AtencionesMedicas != ""
        ? form.AtencionesMedicas
        : [],
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Region: form.Region ? form.Region : "",
    idEnlace: form.idEnlace ? form.idEnlace : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
    idGrupo: form.idGrupo ? form.idGrupo : "",
    idMunicipioGrupo: form.idMunicipioGrupo ? form.idMunicipioGrupo : "",
    idEstatusGrupo: form.idEstatusGrupo ? form.idEstatusGrupo : "",
  };
};

export const FormatNullCedulaFieldsDiagnosticos = (form) => {
  return {
    id: form.id,
    idSolicitud: form.idSolicitud,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : "",
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento ? form.FechaNacimiento : "",
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    idSituacionActual:
      form.idSituacionActual >= 0 ? form.idSituacionActual : "",
    TarjetaImpulso: form.TarjetaImpulso ? form.TarjetaImpulso : "",
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "DIAGNOSTICO",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : "NO APLICA",
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    idParentescoTutor: form.idParentescoTutor ? form.idParentescoTutor : "",
    NombreTutor: form.NombreTutor ? form.NombreTutor : "",
    PaternoTutor: form.PaternoTutor ? form.PaternoTutor : "",
    MaternoTutor: form.MaternoTutor ? form.MaternoTutor : "",
    FechaNacimientoTutor: form.FechaNacimientoTutor
      ? form.FechaNacimientoTutor
      : "",
    EdadTutor: form.EdadTutor ? form.EdadTutor : "",
    SexoTutor: form.SexoTutor ? form.SexoTutor : "",
    idEntidadNacimientoTutor: form.idEntidadNacimientoTutor
      ? form.idEntidadNacimientoTutor
      : "",
    CURPTutor: form.CURPTutor ? form.CURPTutor : "",
    TelefonoTutor: form.TelefonoTutor ? form.TelefonoTutor : "",
    CorreoTutor: form.CorreoTutor ? form.CorreoTutor : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 1,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : 0,
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : 0,
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer ? form.DificultadVer : "",
    DificultadHablar: form.DificultadHablar ? form.DificultadHablar : "",
    DificultadOir: form.DificultadOir ? form.DificultadOir : "",
    DificultadVestirse: form.DificultadVestirse ? form.DificultadVestirse : "",
    DificultadRecordar: form.DificultadRecordar ? form.DificultadRecordar : "",
    DificultadBrazos: form.DificultadBrazos ? form.DificultadBrazos : "",
    DificultadMental: form.DificultadMental < 0 ? "" : form.DificultadMental,
    AsisteEscuela: form.AsisteEscuela < 0 ? 0 : form.AsisteEscuela,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 1,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    idTipoCombustibleAgua: form.idTipoCombustibleAgua
      ? form.idTipoCombustibleAgua
      : "",
    idTipoCombustibleBoiler: form.idTipoCombustibleBoiler
      ? form.idTipoCombustibleBoiler
      : [],
    Refrigerador: form.Refrigerador ? form.Refrigerador : 0,
    Lavadora: form.Lavadora ? form.Lavadora : 0,
    Computadora: form.Computadora ? form.Computadora : 0,
    Estufa: form.Estufa ? form.Estufa : 0,
    Calentador: form.Calentador ? form.Calentador : 0,
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : 0,
    Television: form.Television ? form.Television : 0,
    Internet: form.Internet ? form.Internet : 0,
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : 0,
    Tinaco: form.Tinaco ? form.Tinaco : 0,
    Boiler: form.Boiler ? form.Boiler : 0,
    CantidadCombustibleBoiler: form.CantidadCombustibleBoiler
      ? form.CantidadCombustibleBoiler
      : "",
    GastoCombustibleBoiler: form.GastoCombustibleBoiler
      ? form.GastoCombustibleBoiler
      : "",
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : 0,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Prestaciones: form.Prestaciones ? form.Prestaciones : [],
    Enfermedades: form.Enfermedades ? form.Enfermedades : [],
    AtencionesMedicas:
      form.AtencionesMedicas && form.AtencionesMedicas != ""
        ? form.AtencionesMedicas
        : [],
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Region: form.Region ? form.Region : "",
    idEnlace: form.idEnlace ? form.idEnlace : "",
    Enlace: form.Enlace ? form.Enlace : "",
    Latitud: form.Latitud ? form.Latitud : "",
    Longitud: form.Longitud ? form.Longitud : "",
  };
};

export const CedulaFormInterfaceProyectos = {
  idSolicitud: "",
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  Celular: "",
  Correo: "",
  Telefono: "",
  TelRecados: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: "",
  NumHijas: "",
  Afromexicano: "",
  ComunidadIndigena: "",
  Dialecto: "",
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  NecesidadSolicitante: "",
  CostoNecesidad: 0,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  AGEBVive: "",
  ManzanaVive: "",
  TipoAsentamientoVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  TotalHogares: 1,
  NumeroMujeresHogar: 0,
  NumeroHombresHogar: 0,
  PersonasMayoresEdad: 0,
  PersonasTerceraEdad: 0,
  PersonaJefaFamilia: "H",
  DificultadMovilidad: "",
  DificultadVer: "",
  DificultadHablar: "",
  DificultadOir: "",
  DificultadVestirse: "",
  DificultadRecordar: "",
  DificultadBrazos: "",
  DificultadMental: "",
  AsisteEscuela: "",
  idNivelEscuela: "",
  idGradoEscuela: "",
  idActividades: "",
  IngresoTotalMesPasado: 0,
  PensionMensual: 0,
  IngresoOtrosPaises: 0,
  GastoAlimentos: 0,
  PeriodicidadAlimentos: "",
  GastoVestido: 0,
  PeriodicidadVestido: "",
  GastoEducacion: 0,
  PeriodicidadEducacion: "",
  GastoMedicinas: 0,
  PeriodicidadMedicinas: "",
  GastosConsultas: 0,
  PeriodicidadConsultas: "",
  GastosCombustibles: 0,
  PeriodicidadCombustibles: "",
  GastosServiciosBasicos: 0,
  PeriodicidadServiciosBasicos: "",
  GastosServiciosRecreacion: 0,
  PeriodicidadServiciosRecreacion: "",
  AlimentacionPocoVariada: 0,
  ComioMenos: 0,
  DisminucionComida: 0,
  NoComio: 0,
  DurmioHambre: 0,
  DejoComer: 0,
  PersonasHogar: 1,
  CuartosHogar: 0,
  idTipoVivienda: "",
  idTipoPiso: "",
  idTipoParedes: "",
  idTipoTecho: "",
  idTipoAgua: "",
  idTipoDrenaje: "",
  idTipoLuz: "",
  idTipoCombustible: "",
  Refrigerador: 0,
  Lavadora: 0,
  Computadora: 0,
  Estufa: 0,
  Calentador: 0,
  CalentadorSolar: 0,
  Television: 0,
  Internet: 0,
  TieneTelefono: 0,
  Tinaco: 0,
  Boiler: 0,
  ColoniaSegura: 0,
  ListaParaEnviar: 0,
  Prestaciones: [],
  Enfermedades: [],
  AtencionesMedicas: [],
  Files: [],
  ArchivosClasificacion: [],
  Region: "",
  Enlace: "",
};

export const CedulaFormInterfaceDiagnostico = {
  FechaSolicitud: "",
  FolioTarjetaImpulso: "",
  Folio: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  Edad: "",
  Sexo: "",
  idEntidadNacimiento: "",
  CURP: "",
  RFC: "",
  Celular: "",
  Correo: "",
  Telefono: "",
  TelRecados: "",
  idEstadoCivil: "",
  idParentescoJefeHogar: "",
  NumHijos: "",
  NumHijas: "",
  Afromexicano: "",
  ComunidadIndigena: "",
  Dialecto: "",
  idSituacionActual: "",
  TarjetaImpulso: "",
  ContactoTarjetaImpulso: 0,
  NecesidadSolicitante: "",
  CostoNecesidad: 0,
  idEntidadVive: 12,
  MunicipioVive: "",
  LocalidadVive: "",
  CPVive: "",
  AGEBVive: "",
  ManzanaVive: "",
  TipoAsentamientoVive: "",
  ColoniaVive: "",
  CalleVive: "",
  NoExtVive: "",
  NoIntVive: "",
  Referencias: "",
  TotalHogares: 0,
  NumeroMujeresHogar: 0,
  NumeroHombresHogar: 0,
  PersonasMayoresEdad: 0,
  PersonasTerceraEdad: 0,
  PersonaJefaFamilia: "H",
  DificultadMovilidad: "",
  DificultadVer: "",
  DificultadHablar: "",
  DificultadOir: "",
  DificultadVestirse: "",
  DificultadRecordar: "",
  DificultadBrazos: "",
  DificultadMental: "",
  AsisteEscuela: "",
  idNivelEscuela: "",
  idGradoEscuela: "",
  idActividades: "",
  IngresoTotalMesPasado: 0,
  PensionMensual: 0,
  IngresoOtrosPaises: 0,
  GastoAlimentos: 0,
  PeriodicidadAlimentos: "",
  GastoVestido: 0,
  PeriodicidadVestido: "",
  GastoEducacion: 0,
  PeriodicidadEducacion: "",
  GastoMedicinas: 0,
  PeriodicidadMedicinas: "",
  GastosConsultas: 0,
  PeriodicidadConsultas: "",
  GastosCombustibles: 0,
  PeriodicidadCombustibles: "",
  GastosServiciosBasicos: 0,
  PeriodicidadServiciosBasicos: "",
  GastosServiciosRecreacion: 0,
  PeriodicidadServiciosRecreacion: "",
  AlimentacionPocoVariada: 0,
  ComioMenos: 0,
  DisminucionComida: 0,
  NoComio: 0,
  DurmioHambre: 0,
  DejoComer: 0,
  PersonasHogar: 1,
  CuartosHogar: 1,
  idTipoVivienda: "",
  idTipoPiso: "",
  idTipoParedes: "",
  idTipoTecho: "",
  idTipoAgua: "",
  idTipoDrenaje: "",
  idTipoLuz: "",
  idTipoCombustible: "",
  Refrigerador: 0,
  Lavadora: 0,
  Computadora: 0,
  Estufa: 0,
  Calentador: 0,
  CalentadorSolar: 0,
  Television: 0,
  Internet: 0,
  TieneTelefono: 0,
  Tinaco: 0,
  Boiler: 0,
  ColoniaSegura: 0,
  ListaParaEnviar: 0,
  Prestaciones: [],
  Enfermedades: [],
  AtencionesMedicas: [],
  Files: [],
  ArchivosClasificacion: [],
  Region: "",
  Enlace: "",
};

export const FormatNullCedulaFieldsProyectos = (form) => {
  return {
    id: form.id,
    idSolicitud: form.idSolicitud,
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : "",
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento ? form.FechaNacimiento : "",
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    idSituacionActual: form.idSituacionActual
      ? form.idSituacionActual
      : form.idSituacionActual == 0
      ? 0
      : "",
    TarjetaImpulso: form.TarjetaImpulso ? form.TarjetaImpulso : "",
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : 0,
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 1,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : 0,
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : 0,
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer ? form.DificultadVer : "",
    DificultadHablar: form.DificultadHablar ? form.DificultadHablar : "",
    DificultadOir: form.DificultadOir ? form.DificultadOir : "",
    DificultadVestirse: form.DificultadVestirse ? form.DificultadVestirse : "",
    DificultadRecordar: form.DificultadRecordar ? form.DificultadRecordar : "",
    DificultadBrazos: form.DificultadBrazos ? form.DificultadBrazos : "",
    DificultadMental: form.DificultadMental ? form.DificultadMental : "",
    AsisteEscuela: form.AsisteEscuela < 0 ? "" : form.AsisteEscuela,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 1,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    Refrigerador: form.Refrigerador ? form.Refrigerador : 0,
    Lavadora: form.Lavadora ? form.Lavadora : 0,
    Computadora: form.Computadora ? form.Computadora : 0,
    Estufa: form.Estufa ? form.Estufa : 0,
    Calentador: form.Calentador ? form.Calentador : 0,
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : 0,
    Television: form.Television ? form.Television : 0,
    Internet: form.Internet ? form.Internet : 0,
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : 0,
    Tinaco: form.Tinaco ? form.Tinaco : 0,
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : 0,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Prestaciones: form.Prestaciones ? form.Prestaciones : [],
    Enfermedades: form.Enfermedades ? form.Enfermedades : [],
    AtencionesMedicas:
      form.AtencionesMedicas && form.AtencionesMedicas != ""
        ? form.AtencionesMedicas
        : [],
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Region: form.Region ? form.Region : "",
  };
};

export const FormatNullCedulaFieldsDiagnostico = (form) => {
  return {
    id: form.id ? form.id : "",
    FechaSolicitud: form.FechaSolicitud ? form.FechaSolicitud : "",
    FolioTarjetaImpulso: form.FolioTarjetaImpulso
      ? form.FolioTarjetaImpulso
      : ""
      ? form.FolioTarjetaImpulso
      : "",
    Folio: form.Folio ? form.Folio : "",
    Nombre: form.Nombre ? form.Nombre : "",
    Paterno: form.Paterno ? form.Paterno : "",
    Materno: form.Materno ? form.Materno : "",
    FechaNacimiento: form.FechaNacimiento ? form.FechaNacimiento : "",
    Edad: form.Edad ? form.Edad : "",
    Sexo: form.Sexo ? form.Sexo : "",
    idEntidadNacimiento: form.idEntidadNacimiento
      ? form.idEntidadNacimiento
      : "",
    CURP: form.CURP ? form.CURP : "",
    RFC: form.RFC ? form.RFC : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo : "",
    Telefono: form.Telefono ? form.Telefono : "",
    TelRecados: form.TelRecados ? form.TelRecados : "",
    idEstadoCivil: form.idEstadoCivil ? form.idEstadoCivil : "",
    idParentescoJefeHogar: form.idParentescoJefeHogar
      ? form.idParentescoJefeHogar
      : "",
    NumHijos: form.NumHijos ? form.NumHijos : 0,
    NumHijas: form.NumHijas ? form.NumHijas : 0,
    Afromexicano: form.Afromexicano ? form.Afromexicano : "",
    ComunidadIndigena: form.ComunidadIndigena ? form.ComunidadIndigena : "",
    Dialecto: form.Dialecto ? form.Dialecto : "",
    idSituacionActual: form.idSituacionActual
      ? form.idSituacionActual
      : form.idSituacionActual == 0
      ? 0
      : "",
    TarjetaImpulso: form.TarjetaImpulso ? form.TarjetaImpulso : "",
    ContactoTarjetaImpulso: form.ContactoTarjetaImpulso
      ? form.ContactoTarjetaImpulso
      : 0,
    NecesidadSolicitante: form.NecesidadSolicitante
      ? form.NecesidadSolicitante
      : "",
    CostoNecesidad: form.CostoNecesidad ? form.CostoNecesidad : 0,
    idEntidadVive: form.idEntidadVive ? form.idEntidadVive : 12,
    MunicipioVive: form.MunicipioVive ? form.MunicipioVive : "",
    LocalidadVive: form.LocalidadVive ? form.LocalidadVive : "",
    CPVive: form.CPVive ? form.CPVive : "",
    AGEBVive: form.AGEBVive ? form.AGEBVive : "",
    ManzanaVive: form.ManzanaVive ? form.ManzanaVive : "",
    TipoAsentamientoVive: form.TipoAsentamientoVive
      ? form.TipoAsentamientoVive
      : "",
    ColoniaVive: form.ColoniaVive ? form.ColoniaVive : "",
    CalleVive: form.CalleVive ? form.CalleVive : "",
    NoExtVive: form.NoExtVive ? form.NoExtVive : "",
    NoIntVive: form.NoIntVive ? form.NoIntVive : "",
    Referencias: form.Referencias ? form.Referencias : "",
    TotalHogares: form.TotalHogares ? form.TotalHogares : 1,
    NumeroMujeresHogar: form.NumeroMujeresHogar ? form.NumeroMujeresHogar : 0,
    NumeroHombresHogar: form.NumeroHombresHogar ? form.NumeroHombresHogar : 0,
    PersonasMayoresEdad: form.PersonasMayoresEdad
      ? form.PersonasMayoresEdad
      : 0,
    PersonasTerceraEdad: form.PersonasTerceraEdad
      ? form.PersonasTerceraEdad
      : 0,
    PersonaJefaFamilia: form.PersonaJefaFamilia ? form.PersonaJefaFamilia : "H",
    DificultadMovilidad: form.DificultadMovilidad
      ? form.DificultadMovilidad
      : "",
    DificultadVer: form.DificultadVer ? form.DificultadVer : "",
    DificultadHablar: form.DificultadHablar ? form.DificultadHablar : "",
    DificultadOir: form.DificultadOir ? form.DificultadOir : "",
    DificultadVestirse: form.DificultadVestirse ? form.DificultadVestirse : "",
    DificultadRecordar: form.DificultadRecordar ? form.DificultadRecordar : "",
    DificultadBrazos: form.DificultadBrazos ? form.DificultadBrazos : "",
    DificultadMental: form.DificultadMental ? form.DificultadMental : "",
    AsisteEscuela: form.AsisteEscuela < 0 ? "" : form.AsisteEscuela,
    idNivelEscuela: form.idNivelEscuela ? form.idNivelEscuela : "",
    idGradoEscuela: form.idGradoEscuela ? form.idGradoEscuela : "",
    idActividades: form.idActividades ? form.idActividades : "",
    IngresoTotalMesPasado: form.IngresoTotalMesPasado
      ? form.IngresoTotalMesPasado
      : 0,
    PensionMensual: form.PensionMensual ? form.PensionMensual : 0,
    IngresoOtrosPaises: form.IngresoOtrosPaises ? form.IngresoOtrosPaises : 0,
    GastoAlimentos: form.GastoAlimentos ? form.GastoAlimentos : 0,
    PeriodicidadAlimentos: form.PeriodicidadAlimentos
      ? form.PeriodicidadAlimentos
      : "",
    GastoVestido: form.GastoVestido ? form.GastoVestido : 0,
    PeriodicidadVestido: form.PeriodicidadVestido
      ? form.PeriodicidadVestido
      : "",
    GastoEducacion: form.GastoEducacion ? form.GastoEducacion : 0,
    PeriodicidadEducacion: form.PeriodicidadEducacion
      ? form.PeriodicidadEducacion
      : "",
    GastoMedicinas: form.GastoMedicinas ? form.GastoMedicinas : 0,
    PeriodicidadMedicinas: form.PeriodicidadMedicinas
      ? form.PeriodicidadMedicinas
      : "",
    GastosConsultas: form.GastosConsultas ? form.GastosConsultas : 0,
    PeriodicidadConsultas: form.PeriodicidadConsultas
      ? form.PeriodicidadConsultas
      : "",
    GastosCombustibles: form.GastosCombustibles ? form.GastosCombustibles : 0,
    PeriodicidadCombustibles: form.PeriodicidadCombustibles
      ? form.PeriodicidadCombustibles
      : "",
    GastosServiciosBasicos: form.GastosServiciosBasicos
      ? form.GastosServiciosBasicos
      : 0,
    PeriodicidadServiciosBasicos: form.PeriodicidadServiciosBasicos
      ? form.PeriodicidadServiciosBasicos
      : "",
    GastosServiciosRecreacion: form.GastosServiciosRecreacion
      ? form.GastosServiciosRecreacion
      : 0,
    PeriodicidadServiciosRecreacion: form.PeriodicidadServiciosRecreacion
      ? form.PeriodicidadServiciosRecreacion
      : "",
    AlimentacionPocoVariada: form.AlimentacionPocoVariada
      ? form.AlimentacionPocoVariada
      : 0,
    ComioMenos: form.ComioMenos ? form.ComioMenos : 0,
    DisminucionComida: form.DisminucionComida ? form.DisminucionComida : 0,
    NoComio: form.NoComio ? form.NoComio : 0,
    DurmioHambre: form.DurmioHambre ? form.DurmioHambre : 0,
    DejoComer: form.DejoComer ? form.DejoComer : 0,
    PersonasHogar: form.PersonasHogar ? form.PersonasHogar : 1,
    CuartosHogar: form.CuartosHogar ? form.CuartosHogar : 0,
    idTipoVivienda: form.idTipoVivienda ? form.idTipoVivienda : "",
    idTipoPiso: form.idTipoPiso ? form.idTipoPiso : "",
    idTipoParedes: form.idTipoParedes ? form.idTipoParedes : "",
    idTipoTecho: form.idTipoTecho ? form.idTipoTecho : "",
    idTipoAgua: form.idTipoAgua ? form.idTipoAgua : "",
    idTipoDrenaje: form.idTipoDrenaje ? form.idTipoDrenaje : "",
    idTipoLuz: form.idTipoLuz ? form.idTipoLuz : "",
    idTipoCombustible: form.idTipoCombustible ? form.idTipoCombustible : "",
    Refrigerador: form.Refrigerador ? form.Refrigerador : 0,
    Lavadora: form.Lavadora ? form.Lavadora : 0,
    Computadora: form.Computadora ? form.Computadora : 0,
    Estufa: form.Estufa ? form.Estufa : 0,
    Calentador: form.Calentador ? form.Calentador : 0,
    CalentadorSolar: form.CalentadorSolar ? form.CalentadorSolar : 0,
    Television: form.Television ? form.Television : 0,
    Internet: form.Internet ? form.Internet : 0,
    TieneTelefono: form.TieneTelefono ? form.TieneTelefono : 0,
    Tinaco: form.Tinaco ? form.Tinaco : 0,
    ColoniaSegura: form.ColoniaSegura ? form.ColoniaSegura : 0,
    ListaParaEnviar: form.ListaParaEnviar ? form.ListaParaEnviar : 0,
    Prestaciones: form.Prestaciones ? form.Prestaciones : [],
    Enfermedades: form.Enfermedades ? form.Enfermedades : [],
    AtencionesMedicas:
      form.AtencionesMedicas && form.AtencionesMedicas != ""
        ? form.AtencionesMedicas
        : [],
    Files: form.Files ? form.Files : [],
    ArchivosClasificacion: form.ArchivosClasificacion
      ? form.ArchivosClasificacion
      : [],
    Region: form.Region ? form.Region : "",
    Enlace: form.Enlace ? form.Enlace : "",
  };
};

export const FormatedEncuestaTCS = (form) => {
  return {
    id: form.id ? form.id : "",
    Folio: form.Folio ? form.Folio : "",
    idTipoApoyo: 11,
    TipoEncuesta: form.TipoEncuesta ? form.TipoEncuesta : "E",
    Fecha: moment(form.Fecha).format("DD-MM-YYYY"),
    Nombre: form.Nombre ? form.Nombre.toUpperCase() : "",
    Paterno: form.Paterno ? form.Paterno.toUpperCase() : "",
    Materno: form.Materno ? form.Materno.toUpperCase() : "",
    Sexo: form.Sexo ? form.Sexo : "",
    CURP: form.CURP ? form.CURP.toUpperCase() : "",
    idMunicipio: form.idMunicipio,
    Municipio: form.Municipio ? form.Municipio.toUpperCase() : "",
    idLocalidad: form.idLocalidad,
    Localidad: form.Localidad ? form.Localidad.toUpperCase() : "",
    Colonia: form.Colonia ? form.Colonia.toUpperCase() : "",
    Calle: form.Calle ? form.Calle.toUpperCase() : "",
    CP: form.CP ? form.CP : "",
    NumExt: form.NumExt ? form.NumExt.toUpperCase() : "",
    Telefono: form.Telefono ? form.Telefono : "",
    Celular: form.Celular ? form.Celular : "",
    Correo: form.Correo ? form.Correo.toLowerCase() : "",
    Facebook: form.Facebook ? form.Facebook : "",
    Autoriza: form.Autoriza,
    idCGCSI: form.idCGCSI ? form.idCGCSI : "",
    Ubicacion: form.Referencia ? form.Referencia : "",
    Observaciones: form.Observaciones ? form.Observaciones : "",
    q18: form.q18 ? form.q18 : "S",
    q19: form.q19 ? form.q19 : 3,
    q20: form.q20 ? form.q20 : "",
    q21: form.q21 ? form.q21 : 3,
    q22: {
      r1: form.q22?.r1 ?? false,
      r2: form.q22?.r2 ?? false,
      r3: form.q22?.r3 ?? false,
      r4: form.q22?.r4 ?? false,
      r5: form.q22?.r5 ?? false,
      r6: form.q22?.r6 ?? false,
      r7: form.q22?.r7 ?? false,
      r8: form.q22?.r8 ?? false,
      r9: form.q22?.r9 ?? false,
      r10: form.q22?.r10 ?? "",
    },
    q23: form.q23 ? form.q23 : "",
    q24: form.q24 ? form.q24 : "",
  };
};

export const FormatedEncuesta = (form) => {
  console.log(form);
  return {
    id: form.id,
    Folio: form.Folio ? form.Folio : "",
    idTipoApoyo: form.idTipoApoyo,
    TipoEncuesta: form.TipoEncuesta,
    Fecha: moment(form.Fecha).format("DD-MM-YYYY"),
    Nombre: form.Nombre,
    Paterno: form.Paterno,
    Materno: form.Materno ? form.Materno : "",
    Sexo: form.Sexo ? form.Sexo : "",
    CURP: form.CURP,
    idMunicipio: form.idMunicipio,
    Municipio: form.Municipio,
    idLocalidad: form.idLocalidad,
    Localidad: form.Localidad,
    Colonia: form.Colonia,
    Telefono: form.Telefono ? form.Telefono : "",
    Celular: form.Celular ? form.Celular : "",
    Facebook: form.Facebook ? form.Facebook : "",
    Autoriza: form.Autoriza,
    idCGCSI: form.idCGCSI ? form.idCGCSI : "",
    Ubicacion: form.Referencia ? form.Referencia : "",
    Observaciones: form.Observaciones ? form.Observaciones : "",
    q1: form.q1 ? form.q1 : 3,
    q2: form.q2 ? form.q2 : 3,
    q3: form.q3 ? form.q3 : "",
    q4: form.q4 ? form.q4 : 3,
    q5: form.q5 ? form.q5 : 2,
    q6: form.q6 ? form.q6 : 2,
    q7: form.q7 ? form.q7 : 2,
    q8: form.q8 ? form.q8 : 2,
    q9: form.q9 ? form.q9 : 2,
    q10: form.q10 ? form.q10 : 3,
    q11: form.q11 ? form.q11 : 5,
    q12: form.q12 === 1 ? "S" : "N",
    q13: form.q12 === 0 ? 0 : 3,
    q14: form.q14 ? form.q14 : 2,
    q17: form.q17 ? form.q17 : "",
  };
};

export const EncuestaEntradaForm = {
  Folio: "",
  idTipoApoyo: 0,
  Fecha: moment().format("DD-MM-YYYY"),
  Nombre: "",
  Paterno: "",
  Materno: "",
  Sexo: "",
  CURP: "",
  idMunicipio: 0,
  Municipio: "",
  idLocalidad: 0,
  Localidad: "",
  Colonia: "",
  Telefono: "",
  Celular: "",
  Facebook: "",
  Autoriza: 1,
  idCGCSI: "",
  Ubicacion: "",
  Observaciones: "",
  q1: 3,
  q2: 3,
  q3: "",
  q4: 3,
  q5: 2,
  q6: 2,
  q7: 2,
  q9: 2,
  q10: 3,
  q11: 5,
  q12: "S",
  q13: 2,
  q17: "",
};

export const EncuestaEntradaFormTCS = {
  Folio: "",
  idTipoApoyo: 11,
  Fecha: moment().format("DD-MM-YYYY"),
  Nombre: "",
  Paterno: "",
  Materno: "",
  Sexo: "",
  CURP: "",
  idMunicipio: 0,
  Municipio: "",
  idLocalidad: 0,
  Localidad: "",
  Colonia: "",
  Calle: "",
  NumExt: "",
  CP: "",
  Telefono: "",
  Correo: "",
  Celular: "",
  Facebook: "",
  Autoriza: 1,
  Observaciones: "",
  q18: "S",
  q19: 3,
  q20: "",
  q21: 3,
  q22: {
    r1: false,
    r2: false,
    r3: false,
    r4: false,
    r5: false,
    r6: false,
    r7: false,
    r8: false,
    r9: false,
    r10: "",
  },
  q23: "",
  q24: "",
};
