import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import {
  FormatoCalentador,
  FormatoSolicitudCalentador,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import {
  isEmptyOrNullObject,
  isNullOrUndefined,
} from "../utils/emptyValidations";
import { ValidatorCalentador } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Notifications from "react-notify-toast";
import DateTimeComponent from "../components/DateTimeComponent";
import swal from "sweetalert";

const SolicitudCalentador = (props) => {
  const { data, backSolicitud, archivos } = props;
  const history = useHistory();
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const token = getVars("Token").token;
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [form, setForm] = useState(FormatoSolicitudCalentador);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loadingLocalidad, setLoadingLocalidad] = useState(false);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    municipios: [],
    cat_parentesco_tutor: [],
    archivos_clasificacion: [],
    ejercicios: [],
  });
  const [catLocalidades, setCatLocalidades] = useState({
    localidades: [],
  });
  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [errorsIne, setErrorsIne] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [datosTarjeta, setDatostarjeta] = useState({});
  const [loadingFolio, setLoadingFolio] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 27);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  useEffect(() => {
    EjecutarApi({}, `solicitudes/getCatalogos/${2}`, token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          cat.localidades = [];
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  }, [token]);

  useEffect(() => {
    setIsLoadingForm(true);
    let formData = { ...form };

    if (Datos != null) {
      EjecutarApi(
        {},
        `calentadoresSolares/getSolicitud/${Datos.id}`,
        token,
        "GET"
      )
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            setIsForEdit(true);
            console.log(res.data);
            const newForm = FormatoCalentador(res.data);
            getLocalities(newForm.idMunicipio);
            setForm({
              ...newForm,
            });
            setIsLoadingForm(false);
            setFormLoaded(true);
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
            backSolicitud();
          }
          setIsLoadingForm(false);
          setFormLoaded(true);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
          backSolicitud();
        });
    } else {
      setForm(formData);
      setIsLoadingForm(false);
      setFormLoaded(true);
    }
  }, [Datos]);

  const getLocalities = (municipio) => {
    setLoadingLocalidad(true);
    EjecutarApi(
      {},
      `cedula/getLocalidadesByMunicipio/${municipio}`,
      token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatLocalidades({
            localidades: res.data,
          });
          setLoadingLocalidad(false);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
        setLoadingLocalidad(false);
      });
  };

  const validateData = async (type, value) => {
    var urlencoded = new URLSearchParams();
    let folio = type === "Folio" ? value : "";
    let curp = type === "CURP" ? value.toUpperCase() : "";
    urlencoded.append("token", "dfee39bd-acd2-4c95-514d-74384d530496");
    urlencoded.append("Folio", folio);
    urlencoded.append("CURP", curp);

    if (type === "CURP") {
      validateCurp(value);
    } else {
      setLoadingCURP(false);
      swal({
        title: "¡Atención!",
        text: "No se encuentra la persona con los datos proporcionados",
        icon: "warning",
      });
    }

    // var requestInfo = {
    //   method: "POST",
    //   headers: new Headers({
    //     "Content-Type": "application/x-www-form-urlencoded",
    //   }),
    //   body: urlencoded,
    //   redirect: "follow",
    // };
    // setLoadingCURP(true);
    // setLoadingFolio(true);
    // fetch(
    //   "https://peb.guanajuato.gob.mx/Tarjeta/api/APIBusquedaDatos/SETBusquedaCLUB",
    //   requestInfo
    // )
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((dataRS) => {
    //     if (dataRS.result === 0) {
    //       if (type === "CURP") {
    //         validateCurp(value);
    //       } else {
    //         setLoadingCURP(false);
    //         swal({
    //           title: "¡Atención!",
    //           text: "No se encuentra la persona con los datos proporcionados",
    //           icon: "warning",
    //         });
    //       }
    //       setLoadingFolio(false);
    //     } else {
    //       setLoadingFolio(false);
    //       setLoadingCURP(false);
    //       setDatostarjeta(dataRS.data);
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadingFolio(false);
    //     setLoadingCURP(false);
    //     setLoadingCatalogs(false);
    //     swal({
    //       title: "¡Atención!",
    //       text: error.error,
    //       icon: "warning",
    //     });
    //   });
  };

  const validateCurp = (CURP, index = 0) => {
    if (CURP.length < 18) {
      return;
    }
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        if (dataRS.Mensaje === "OK") {
          const response = dataRS.Resultado;
          const fechaNacimiento = moment(response.fechNac, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          let data = {};
          if (index === 1) {
            data = {
              NombreTutor: response.nombres,
              PaternoTutor: response.apellido1,
              MaternoTutor: response.apellido2,
              CURPTutor: response.CURP,
            };
            setForm({
              ...form,
              ...data,
            });
          } else {
            data = {
              Nombre: response.nombres,
              Paterno: response.apellido1,
              Materno: response.apellido2,
              Sexo: response.sexo,
              FechaNacimiento: fechaNacimiento,
              Edad: getAgeByDate(fechaNacimiento),
              idEntidadNacimiento: getEstadoNacimiento(response.cveEntidadNac),
              FolioTarjetaImpulso: "",
            };
            setForm({
              ...form,
              ...data,
            });
            setDatostarjeta({});
          }
        } else {
          swal({
            title: "¡Atención!",
            text: dataRS.Mensaje,
            icon: "warning",
          });
        }
        setLoadingCURP(false);
      })
      .catch((error) => {
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "¡Atención!",
          text: error.error,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    if (!isEmptyOrNullObject(datosTarjeta)) {
      let data = {
        CURP: datosTarjeta[0].CURP,
        Sexo: datosTarjeta[0].Sexo,
        Edad: getAgeByDate(datosTarjeta[0].FechaNacimiento),
        FechaNacimiento: moment(
          datosTarjeta[0].FechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        FolioTarjetaImpulso: datosTarjeta[0].Folio ? datosTarjeta[0].Folio : "",
        Nombre: datosTarjeta[0].Nombres,
        Paterno: datosTarjeta[0].PrimerApellido,
        Materno: datosTarjeta[0].SegundoApellido,
      };
      data.idEntidadNacimiento = getEstadoNacimiento(
        datosTarjeta[0].EntidadNacimiento
      );
      setForm({
        ...form,
        ...data,
      });
    }
  }, [datosTarjeta]);

  useEffect(() => {
    if (!isNaN(parseInt(form.idMunicipio))) {
      getLocalities(form.idMunicipio);
    }
  }, [form.idMunicipio]);

  const getAgeByDate = (fechaNacimiento) => {
    if (fechaNacimiento) {
      let anio = fechaNacimiento.split("/");
      let posicion = 2;
      if (anio.length < 2) {
        anio = fechaNacimiento.split("-");
        posicion = 0;
      }
      return moment().diff(anio[posicion], "years");
    } else return 0;
  };

  const getEstadoNacimiento = (estadoCurp) => {
    const found = catalogs.entidades.find(
      (element) => element.Clave_CURP === estadoCurp
    );
    if (!isNullOrUndefined(found)) {
      return found.value;
    }
    return "";
  };

  const saveSolicitud = (redirect = 0) => {
    const hasErrors = ValidatorCalentador(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let url;
      if (isForEdit) {
        url = "calentadoresSolares/updateSolicitud";
      } else {
        url = "calentadoresSolares/createSolicitud";
      }
      let formData = { ...form };
      let oldFiles = [];
      let newFiles = [];
      let oldClasification = [];
      let newClasification = [];
      let flag = false;

      formData.Files.forEach((file, index) => {
        if (isNullOrUndefined(file.id)) {
          newFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          newClasification.push(formData.ArchivosClasificacion[index]);
        } else {
          oldFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          oldClasification.push(formData.ArchivosClasificacion[index]);
        }
      });
      if (flag) {
        swal({
          title: "Alerta",
          text: "Debe seleccionar una clasificación para cada archivo",
          icon: "warning",
        });
        return;
      }
      formData.OldFiles = oldFiles;
      formData.OldClasificacion = oldClasification;
      formData.NewFiles = newFiles;
      formData.NewClasificacion = newClasification;
      formData.Files = [];
      formData.ArchivosClasificacion = [];
      formData.NumInt = formData.NumInt ? "S/N" : formData.NumInt;

      switch (redirect) {
        case 1:
          formData.idEstatusSolicitud = 14;
          break;
        case 2:
          formData.idEstatusSolicitud = 15;
          break;
        case 3:
          formData.idEstatusSolicitud = 16;
          break;
        default:
          formData.idEstatusSolicitud = 1;
          break;
      }
      // if (redirect) {
      //   formData.idEstatusSolicitud = 14;
      // }
      uploadFiles({ data: formData, api: url, token })
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            swal({
              title: "Guardado con éxito",
              text: res.message,
              icon: "success",
            });
            setIsLoadingForm(false);
            backSolicitud();
          } else {
            swal({
              title: "Atención",
              text: res.message,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Atención",
            text: error.message,
            icon: "warning",
          });
        });
    }
  };

  const deleteAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de eliminar la solicitud?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        deleteSolicitud();
      }
    });
  };

  const deleteSolicitud = () => {
    setIsLoadingForm(true);
    EjecutarApi({ id: form.id }, "calentadoresSolares/deleteSolicitud", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Eliminada con éxito!",
            icon: "success",
          });
          backSolicitud();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((error) => {
        setIsLoadingForm(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.message,
          icon: "warning",
        });
      });
  };

  const handleChangeMunicipio = (municipio) => {
    if (municipio !== null && municipio !== "") {
      getLocalities(municipio);
      setForm({
        ...form,
        idMunicipio: municipio,
      });
    } else {
      setForm({
        ...form,
        idMunicipio: "",
        idLocalidad: "",
      });
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(form.FechaINE))) {
      if (form.FechaINE.length === 4) {
        let fecha_actual = new Date();
        let aa_actual = fecha_actual.getFullYear();
        if (form.FechaINE !== null && form.FechaINE !== "") {
          if (
            parseInt(form.FechaINE) < parseInt(aa_actual) &&
            parseInt(form.FechaINE) < parseInt(form.Ejercicio)
          ) {
            const errors = {
              error: true,
              seccion: 1,
              message: "La vigencia de la Identificación Oficial no es válida",
            };
            setErrorsIne(errors);
          } else {
            const errors = { error: false, seccion: -1, message: "" };
            setErrorsIne(errors);
          }
        }
      } else {
        if (form.FechaINE.length > 0) {
          const errors = {
            error: true,
            seccion: 1,
            message: "La vigencia de la Identificación Oficial no es válida",
          };
          setErrorsIne(errors);
        }
      }
    }
  }, [form.FechaINE]);

  return (
    <>
      <Notifications />
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col size="2">
                    <Button color="success" onClick={backSolicitud}>
                      Regresar
                    </Button>
                  </Col>
                  <Col sm={{ size: "2", offset: "6" }}>
                    {isForEdit && Datos && (
                      <Button
                        color="info"
                        style={{ paddingLeft: "2rem" }}
                        disabled={loadingForm}
                        onClick={() => archivos(form, menu)}
                      >
                        Archivos
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  {isForEdit
                    ? form.idEstatusSolicitud === 5
                      ? "Solicitud Validada"
                      : "Editar solicitud"
                    : "Nueva Solicitud"}
                  {loadingForm && <Spinner />}
                </CardTitle>
                {formLoaded && (
                  <Form>
                    <Col sm="12" style={{ textAlign: "center", color: "red" }}>
                      {form.idEstatusSolicitud === 5 && (
                        <CardText>
                          La solicitud ya se encuentra validada, no se puede
                          editar
                        </CardText>
                      )}
                    </Col>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 0
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Fecha solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <DateTimeComponent
                            id="FechaSolicitud"
                            name="FechaSolicitud"
                            dateFormat="YYYY-MM-DD"
                            maxDate={moment()
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            minDate={new Date("2023-03-01")}
                            timeFormat={false}
                            closeOnSelect={true}
                            value={form.FechaSolicitud}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaSolicitud: moment(e._d).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            }
                            disabled={menu.Agregar === 0}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Folio tarjeta impulso</Label>
                          <Input
                            id="folio"
                            type="text"
                            maxLength={10}
                            value={form.FolioTarjetaImpulso}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                FolioTarjetaImpulso: event.target.value,
                              })
                            }
                          />
                          {/* {form.idEstatusSolicitud === 1 &&
                            menu.Agregar === 1 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.FolioTarjetaImpulso.length === 0}
                                onClick={() =>
                                  validateData(
                                    "Folio",
                                    form.FolioTarjetaImpulso
                                  )
                                }
                              >
                                Validar {loadingFolio && <Spinner size="sm" />}
                              </Button>
                            )} */}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            CURP<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CURP"
                            type="text"
                            value={form.CURP}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CURP: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          {form.idEstatusSolicitud === 1 &&
                            menu.Agregar === 1 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.CURP.length !== 18}
                                onClick={() => validateData("CURP", form.CURP)}
                              >
                                Validar {loadingCURP && <Spinner size="sm" />}
                              </Button>
                            )}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Folio de solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Folio"
                            type="text"
                            value={form.Folio}
                            disabled={true}
                            style={{ textTransform: "uppercase" }}
                          />
                        </FormGroup>
                      </Col>
                      {menu.Editar === 1 && !loadingForm && (
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label check>
                              Tipo de apoyo
                              <span className="text-danger">*</span>
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="SoloCalentador"
                                    type="radio"
                                    checked={
                                      form.Formato === "1" || form.Formato === 1
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Formato: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Solo Calentador</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="CalentadorYBase"
                                    type="radio"
                                    checked={
                                      form.Formato === "2" || form.Formato === 2
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Formato: 2,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Calentador y Base</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      )}
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Ejercicio:</Label>
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="Ejercicio"
                            type="select"
                            disabled={loadingCatalogs}
                            value={form.Ejercicio}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Ejercicio: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.ejercicios.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      {errors.error && errors.seccion === 0 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          (errors.error && errors.seccion === 1) ||
                          (errorsIne.error && errorsIne.seccion === 1)
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      I. DATOS DEL SOLICITANTE
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Nombre(s) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Nombre}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Nombre: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Primer apellido
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="paterno"
                            type="text"
                            value={form.Paterno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Paterno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Segundo apellido</Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Materno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Materno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label check>
                            Sexo<span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="Hombre"
                                  type="radio"
                                  checked={
                                    form.Sexo === "H" || form.Sexo === ""
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "H",
                                    })
                                  }
                                />{" "}
                                <Label check>Hombre</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="Mujer"
                                  type="radio"
                                  checked={form.Sexo === "M"}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "M",
                                    })
                                  }
                                />{" "}
                                <Label check>Mujer</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Vigencia INE:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FechaINE"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={4}
                            value={form.FechaINE}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                FechaINE: e.target.value.replace(/\D/, ""),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      ) : errorsIne.error && errorsIne.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errorsIne.message}
                          </CardText>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 5
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      V. DATOS DE DOMICILIO
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Calle: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Calle"
                            type="text"
                            value={form.Calle}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Calle: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>
                            No. Exterior: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NumExt"
                            type="text"
                            value={form.NumExt}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NumExt: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>No. Interior:</Label>
                          <Input
                            name="NumInt"
                            type="text"
                            value={form.NumInt}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NumInt: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>
                            Código Postal:<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CP"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={5}
                            value={form.CP}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CP: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Colonia: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Colonia"
                            type="text"
                            value={form.Colonia}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Colonia: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Municipio:</Label>
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="Municipio"
                            type="select"
                            disabled={loadingCatalogs}
                            value={form.idMunicipio}
                            onChange={(e) =>
                              handleChangeMunicipio(e.target.value)
                            }
                          >
                            <option value={""}></option>
                            {catalogs.municipios.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Localidad:</Label>
                          <span className="text-danger">*</span>
                          {loadingLocalidad && <Spinner size="sm" />}
                          <Input
                            name="Localidad"
                            type="select"
                            disabled={
                              loadingLocalidad || form.idMunicipio === ""
                            }
                            value={form.idLocalidad}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idLocalidad: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catLocalidades.localidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Estado: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="idEntidadVive"
                            type="select"
                            value={12}
                            disabled={true}
                          >
                            <option value={""}></option>
                            {catalogs.entidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>
                            Referencias: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Referencias"
                            type="textarea"
                            value={form.Referencias}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Referencias: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 5 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 2
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      II. DATOS DE CONTACTO
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Teléfono celular:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Celular}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Celular: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Teléfono fijo:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Telefono}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Telefono: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Correo electrónico:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            value={form.Correo}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Correo: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 2 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 3
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      III. DATOS DEL ACOMPAÑANTE (en caso de aplicar)
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Parentesco con la persona solicitante:</Label>
                          <Input
                            name="idParentescoTutor"
                            type="select"
                            value={form.idParentescoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idParentescoTutor: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.cat_parentesco_tutor.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            CURP<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CURP"
                            type="text"
                            value={form.CURPTutor}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CURPTutor: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          {form.idEstatusSolicitud === 1 &&
                            menu.Agregar === 1 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.CURPTutor.length !== 18}
                                onClick={() => validateCurp(form.CURPTutor, 1)}
                              >
                                Validar {loadingCURP && <Spinner size="sm" />}
                              </Button>
                            )}
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Nombre(s):</Label>
                          <Input
                            name="NombreTutor"
                            type="text"
                            value={form.NombreTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NombreTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Primer apellido:</Label>
                          <Input
                            name="PaternoTutor"
                            type="text"
                            value={form.PaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                PaternoTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Segundo apellido:</Label>
                          <Input
                            name="MaternoTutor"
                            type="text"
                            value={form.MaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                MaternoTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 3 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 6
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VI. DATOS DE ENLACE
                    </CardSubtitle>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label>Persona que levantó solicitud en campo:</Label>
                          <Input
                            name="Enlace"
                            type="text"
                            value={form.Enlace}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Enlace: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 6 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 7
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="12" style={{ textAlign: "center" }}>
                        <Button
                          color="warning"
                          disabled={loadingForm}
                          onClick={backSolicitud}
                        >
                          Cancelar
                        </Button>

                        <>
                          {isForEdit &&
                            menu.Eliminar === 1 &&
                            form.idEstatusSolicitud != 14 &&
                            !loadingForm && (
                              <Button
                                color="danger"
                                disabled={loadingForm}
                                onClick={() => deleteAlert()}
                              >
                                Eliminar
                              </Button>
                            )}

                          {menu.Agregar === 1 &&
                            menu.Editar === 1 &&
                            form.idEstatusSolicitud != 14 &&
                            !loadingForm && (
                              <Button
                                disabled={loadingForm}
                                onClick={() => saveSolicitud()}
                              >
                                Guardar
                              </Button>
                            )}

                          {menu.Editar === 1 &&
                            menu.Imprimir === 1 &&
                            !loadingForm &&
                            form.idEstatusSolicitud != 14 &&
                            isForEdit && (
                              <Button
                                color="primary"
                                disabled={loadingForm}
                                onClick={() => saveSolicitud(1)}
                              >
                                Aprobada Comité
                              </Button>
                            )}

                          {menu.Editar === 1 &&
                            menu.Imprimir === 1 &&
                            !loadingForm &&
                            isForEdit && (
                              <Button
                                color="warning"
                                disabled={loadingForm}
                                onClick={() => saveSolicitud(2)}
                              >
                                Lista de Espera
                              </Button>
                            )}

                          {menu.Editar === 1 &&
                            menu.Imprimir === 1 &&
                            !loadingForm &&
                            isForEdit && (
                              <Button
                                color="danger"
                                disabled={loadingForm}
                                onClick={() => saveSolicitud(3)}
                              >
                                Baja
                              </Button>
                            )}

                          {menu.Editar === 1 &&
                            menu.Imprimir === 1 &&
                            !loadingForm &&
                            isForEdit && (
                              <Button
                                color="success"
                                disabled={loadingForm}
                                onClick={() => saveSolicitud(4)}
                              >
                                Por validar
                              </Button>
                            )}

                          {loadingForm && <Spinner />}
                        </>
                      </Col>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {form.idEstatusSolicitud === 5 && (
                          <CardText>
                            La solicitud se encuentra validada, no se puede
                            editar
                          </CardText>
                        )}
                        {form.idEstatusSolicitud === 14 && (
                          <CardText>
                            La solicitud fué aprobada por comité, no se puede
                            editar
                          </CardText>
                        )}
                        {errors.error && (
                          <CardText>
                            Existen errores, por favor verifique el formulario
                          </CardText>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SolicitudCalentador;
